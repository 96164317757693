import React, {useState, useEffect} from 'react';
import FolderContent from "./FolderContent";
import FileContent from "./FileContent";
import {Modal, Button, Form} from "react-bootstrap";
import SearchBar from 'material-ui-search-bar'


function Folder({}) {
    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const[formName, setFormName] = useState('');
    const [folderShow, setFolderShow] = useState(false);
    const [fileShow, setFileShow] = useState(false);
    const [filePath, setFilePath] = useState('');
    const [filePathId, setFilePathId] = useState('');
    const [allFolders, setAllFolders] = useState([]);
    const[searchBarText, setSearchBarText] = useState('');

    const[folderNameEdit, setFolderNameEdit] = useState('');

    const[formFile, setFormFile] = useState('');

    const handleFolderClose = () => setFolderShow(false);
    const handleFolderShow = () => setFolderShow(true);

    const handleFileClose = () => setFileShow(false);
    const handleFileShow = () => setFileShow(true);


    const fetchFunction = () => {
        fetch('http://' + location.hostname + '/admin/folders')
            .then(res => res.clone().json())
            .then((json) => {
                setFolders([...json.folders]);
                setAllFolders([...json.allFolders]);
            })
            .catch((e) => {
                console.log("Error:", e);
            });
    };

    useEffect(fetchFunction, []);

    let handleFormChange = (e) => {

        if (e.target) {
            if (e.target.name === 'name') {
                setFormName(e.target.value);
            }

            if (e.target.name === 'file') {
                setFormFile(e.target.files[0]);
            }
        }
    };

    let handleFormSubmit = (e) => {
        handleFolderSubmit(formName);

        handleFolderClose();
    };

    let handleFolderUpdate = (formId, formName) => {
        let url = 'http://' + location.hostname + '/admin/folder/' + formId + '/update';

        $.ajax({
            url: url,
            type: 'POST',
            data: {
                id: formId,
                name: formName,
            },
            dataType: 'json',
            success: function(response) {

                if (response.error) {

                } else if (response.folders) {
                    console.log(response);
                    setFolders([...response.folders]);
                    setFiles([...response.files]);
                    setAllFolders([...response.allFolders]);
                    setFilePath(response.path);
                    setFilePathId(response.folderId);
                }
            }.bind(this),
            error: function(xhr) {
                console.log(`An error occured: ${xhr.status} ${xhr.statusText}`);
            }
        });
    };

    let handleMoveFile = (fileId, folder) => {
        let url = 'http://' + location.hostname + '/admin/file/' + fileId + '/update';

        $.ajax({
            url: url,
            type: 'POST',
            data: {
                id: fileId,
                folder: folder,
            },
            dataType: 'json',
            success: function(response) {

                if (response.error) {

                } else if (response.folders) {
                    setFilePath(response.path);
                    setFolders([...response.folders]);
                    setFiles([...response.files])
                }
            }.bind(this),
            error: function(xhr) {
                console.log(`An error occured: ${xhr.status} ${xhr.statusText}`);
            }
        });
    };

    let removeDeletedFile = (fileId) => {
        let nonDeletedFiles = files.filter(file => file.id != fileId);
        setFiles([...nonDeletedFiles]);
    };

    let handleDeleteFile = (fileId) => {
        let url = 'http://' + location.hostname + '/admin/file/' + fileId + '/delete';

        $.ajax({
            url: url,
            type: 'POST',
            data: {
                id: fileId,
            },
            dataType: 'json',
            success: function(response) {

                console.log(response);
                if (response.error) {

                } else if (response.fileUploadId) {
                    removeDeletedFile(response.fileUploadId);
                }
            }.bind(this),
            error: function(xhr) {
                console.log(`An error occured: ${xhr.status} ${xhr.statusText}`);
            }
        });
    };

    let removeDeletedFolder = (folderId) => {
        let nonDeletedFolders = folders.filter(folder => folder.id != folderId);
        let nonDeletedAllFolders = allFolders.filter(folder => folder.id != folderId);
        setFolders([...nonDeletedFolders]);
        setAllFolders([...nonDeletedAllFolders]);

    };

    let handleDeleteFolder = (folderId) => {
        console.log(folderId);

        let url = 'http://' + location.hostname + '/admin/folder/' + folderId + '/delete';

        $.ajax({
            url: url,
            type: 'POST',
            data: {
                id: folderId,
            },
            dataType: 'json',
            success: function(response) {

                console.log(response);
                if (response.error) {

                } else if (response.folderId) {
                    removeDeletedFolder(response.folderId);
                }
            }.bind(this),
            error: function(xhr) {
                console.log(`An error occured: ${xhr.status} ${xhr.statusText}`);
            }
        });
    };

    let handleFolderSubmit = (formName) => {
        console.log(filePathId);
        let url = 'http://' + location.hostname + '/admin/create-folder';

        if (filePathId) {
            url = 'http://' + location.hostname + '/admin/folder/' + filePathId + '/create-folder';
        }

        $.ajax({
            url: url,
            type: 'POST',
            data: {
                name: formName,
            },
            dataType: 'json',
            success: function(response) {

                if (response.error) {

                } else if (response.path) {
                    console.log(response);
                    setFormName('');
                    setFolders([...response.folders]);
                    setFiles([...response.files]);
                    setAllFolders([...response.allFolders]);
                    setFilePath(response.path);
                    setFilePathId(response.folderId);
                }
            }.bind(this),
            error: function(xhr) {
                console.log(`An error occured: ${xhr.status} ${xhr.statusText}`);
            }
        });
    };

    let handleFileSubmit = (e) => {
        console.log(formFile);
        handleFileSubmitToDatabase(formFile);

        handleFileClose();
    };

    let handleFileSubmitToDatabase = (formFile) => {
        console.log(filePathId);

        let files = new FormData();
        files.append('fileName', formFile);

        let url = 'http://' + location.hostname + '/admin/folder/' + filePathId + '/create-file';

        $.ajax({
            url: url,
            type: 'POST',
            data: files,
            processData: false,
            contentType: false,
            // dataType: 'json',
            success: function(response) {

                if (response.error) {

                } else if (response) {

                    setFolders([...response.folders]);
                    setFiles([...response.files]);
                }
            }.bind(this),
            error: function(xhr) {
                console.log(`An error occured: ${xhr.status} ${xhr.statusText}`);
            }
        });
    };

    let folderContents = (id, name) => {
        setFilePathId(id);

        fetch('http://' + location.hostname + '/admin/folder/' + id + '/contents')
            .then(res => res.clone().json())
            .then((json) => {
                setFolders([...json.folders]);
                setFiles([...json.files]);
                setFilePath(json.path);
            })
            .catch((e) => {
                console.log("Error:", e);
            });
    };

    let handleOnBlur = (e) => {
        if (e.target.name === 'search[searchBarText]') {

        }
    };

    let handleChange = (e) => {
        setSearchBarText(e)
    };

    let clearChange = (e) => {
        setSearchBarText('');

        // let delayInMilliseconds = 2000;
        //
        // console.log(searchBarText);
        //
        // setTimeout(function() {
        //     findSearchBar(searchBarText);
        // }, delayInMilliseconds);
    };

    const handleSearchBarKeyDown = (event, type) => {
        const { key } = event;
        const enterKeys = ["Enter"];

        if (
            (event.target.name === 'search[searchBarText]')
        ) {
            if (enterKeys.indexOf(key) > -1) {
                setSearchBarText(event.target.value);
                findSearchBar(searchBarText);
            }
        }
    };

    let findSearchBar = (searchBarText) => {
        let url = 'http://' + location.hostname + '/admin/search';

        if (searchBarText === '') {
            setSearchBarText('');
        }

        $.ajax({
            url: url,
            type: 'POST',
            data: {
                searchBarText: searchBarText,
            },
            dataType: 'json',
            success: function(response) {

                if (response.error) {

                } else if (response.folders) {
                    setFormName('');
                    setFolders([...response.folders]);
                    setFiles([...response.files]);
                    setFilePath('');
                    setFilePathId('');
                }

            }.bind(this),
            error: function(xhr) {
                console.log(`An error occured: ${xhr.status} ${xhr.statusText}`);
            }
        });
    };

    return (
    <>
        <div className="mb-1">
            <div className="container-btn">
                <div className="text-left">
                    <div>
                        <SearchBar
                            name="search[searchBarText]"
                            value={searchBarText}
                            onChange={handleChange}
                            onRequestSearch={() => findSearchBar(searchBarText)}
                            onCancelSearch={() => findSearchBar('') }
                        />
                         {/*<input className="search-bar" name="search[searchBarText]" type="text" placeholder="Search" value={searchBarText} onChange={handleChange} onKeyDown={handleSearchBarKeyDown} />*/}
                    </div>

                    { filePath.length > 0 ?
                        <span>
                            <a href="/admin/dashboard">Home</a> <span>/</span>
                        </span>
                        :
                        <></>
                    }
                    { filePath.length > 0 ? filePath.map((file, key) =>
                        <span key={key}>
                            {
                                filePath.length - 1 === key ?
                                    <span>{file.name}</span>
                                    :
                                    <a key={key} className="" onClick={() => folderContents(file.id, file.name)}>
                                        <span>{file.name}</span>
                                    </a>
                            }
                                <span> / </span>
                        </span>

                ): ''}
                </div>
                <div className="text-right">`
                    <button className="btn btn-sm btn-success" onClick={handleFolderShow}>
                        <i className="fa fa-folder" aria-hidden="true"></i> Add { filePathId ? 'Sub Folder' : 'Folder' }
                    </button>

                    {
                        filePathId ?
                            <button className="btn btn-sm btn-success ml-1" onClick={handleFileShow}>
                                <i className="fa fa-file" aria-hidden="true"></i> Add File
                            </button>
                            :
                            <></>
                    }
                    <p>*Create Folder</p>
                </div>
            </div>

            <Modal
                show={folderShow}
                onHide={handleFolderClose}
                animation={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" value={formName} onChange={handleFormChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleFolderClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleFormSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={fileShow}
                onHide={handleFileClose}
                animation={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formName">
                        <Form.Label>Add a File</Form.Label>
                        <Form.File
                            id="custom-file"
                            name="file"
                            custom
                            onChange={handleFormChange}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleFileClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleFileSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

        <div className="d-flex">
            { folders.map((folder, key) =>
                <FolderContent key={key} folder={folder} folderContents={folderContents} handleFolderUpdate={handleFolderUpdate} handleDeleteFolder={handleDeleteFolder} />
            ) }
        </div>

        {
            files.length > 0 ?
                <div className="file-container table-responsive mt-3">
                    <table className="table table-striped ">
                        <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Uploaded</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {files.map((file, key) =>
                                <FileContent key={key} file={file} allFolders={allFolders} handleMoveFile={handleMoveFile} handleDeleteFile={handleDeleteFile} />
                        )}
                        </tbody>
                    </table>
                </div>
                :
                <>
                </>
        }
    </>
);
}

export default Folder;