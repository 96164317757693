import React, {useState, useEffect} from 'react';
import FolderContent from "./FolderContent";
import Select from 'react-select';
import {Modal, Button, Form} from "react-bootstrap";

function FileContent({file, allFolders, handleMoveFile, handleDeleteFile}) {
    const [id, setId] = useState(file.id);
    const [name, setName] = useState(file.name);
    const [url, setUrl] = useState(file.url);
    const [uploadedAt, setCreatedAt] = useState(file.createdAt);

    let resetState = () => {
        setId(file.id);
        setName(file.name);
        setUrl(file.url);
        setCreatedAt(file.createdAt);
        setOptions(allFolders.map((folder, key) => createOption(folder.path, folder.id)));
    };

    useEffect(resetState, [file]);

    const createOption = (label, value) => ({
        label: label,
        value: value,
    });

    const[options, setOptions] = useState(allFolders.map((folder, key) => createOption(folder.path, folder.id)));
    const[folderForm, setFolderForm] = useState('');

    const findOption = (value) => {
        let option = null;

        options.filter((el,i) => { if(el.value === value){
            option = el;
        } });

        return option;
    };


    const [fileMoveShow, setFileMoveShow] = useState(false);
    const handleFileMoveClose = () => setFileMoveShow(false);
    const handleFileMoveShow = () => setFileMoveShow(true);

    const [fileDeleteShow, setFileDeleteShow] = useState(false);
    const handleFileDeleteClose = () => setFileDeleteShow(false);
    const handleFileDeleteShow = () => setFileDeleteShow(true);

    let handleSelectChange = (newValue, actionMeta) => {
        setFolderForm(newValue);
    };

    let handleFormSubmit = (e) => {
        handleMoveFile(id, folderForm);

        handleFileMoveClose();
        setFolderForm('');
    };

    let handleDelete = (e) => {
        handleDeleteFile(id);

        handleFileDeleteClose();
    };


    return (
        <>
            <tr>
                <td>
                    <a className="file" href={url}>
                        <i className="fa fa-file" aria-hidden="true"></i>
                        <span>{name}</span>
                    </a>
                </td>
                <td>
                    {uploadedAt}
                </td>
                <td className="actions">
                    <div>
                        <Modal
                            show={fileMoveShow}
                            onHide={handleFileMoveClose}
                            animation={false}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Move File</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group controlId="folder">
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="folder"
                                        // defaultValue={options[0]}
                                        placeholder="Select a Folder"
                                        isSearchable
                                        onChange={handleSelectChange}
                                        options={options}
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFileMoveClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleFormSubmit}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={fileDeleteShow}
                            onHide={handleFileDeleteClose}
                            animation={false}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Delete File</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure you want to delete '{name}'?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFileDeleteClose}>
                                    No
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Yes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <a className="btn btn-info btn-sm" onClick={handleFileMoveShow}>
                        <i className="fa fa-arrows" aria-hidden="true"></i>
                    </a>

                    <a className="btn btn-danger btn-sm" onClick={handleFileDeleteShow}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                    </a>
                </td>
            </tr>
        </>
    )
}

export default FileContent;