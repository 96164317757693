import React from 'react';
import ReactDOM from 'react-dom';
import Folder from "./components/Folder";

const adminFolder = document.getElementById('admin-folder-root');

console.log(adminFolder);

if (adminFolder) {
    try {
        ReactDOM.render(<Folder {...(adminFolder.dataset)}/>, adminFolder);
    } catch (error) {
        console.error(error);
    }
}