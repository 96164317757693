import React, {useState, useEffect} from 'react';
import {Button, Form, Modal} from "react-bootstrap";

function FolderContent({folder, folderContents, handleFolderUpdate, handleDeleteFolder}) {
    const [id, setId] = useState(folder.id);
    const [name, setName] = useState(folder.name);
    const [formName, setFormName] = useState(folder.name);

    const [folderEditShow, setFolderEditShow] = useState(false);
    const handleFolderEditClose = () => setFolderEditShow(false);
    const handleFolderEditShow = () => setFolderEditShow(true);

    const [folderDeleteShow, setFolderDeleteShow] = useState(false);
    const handleFolderDeleteClose = () => setFolderDeleteShow(false);
    const handleFolderDeleteShow = () => setFolderDeleteShow(true);

    let resetState = () => {
        setId(folder.id);
        setName(folder.name);
        setFormName(folder.name);
    };

    useEffect(resetState, [folder]);

    let handleFormChange = (e) => {

        if (e.target) {
            if (e.target.name === 'name') {
                setFormName(e.target.value);
            }
        }
    };

    let handleFormSubmit = (e) => {
        handleFolderUpdate(id, formName);

        handleFolderEditClose();
    };

    let handleDelete = (e) => {
        console.log(id);
        handleDeleteFolder(id);

        handleFolderDeleteClose();
    };

    return (
        <>
            <div>
                <Modal
                    show={folderEditShow}
                    onHide={handleFolderEditClose}
                    animation={false}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Folder</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" value={formName} onChange={handleFormChange} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleFolderEditClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleFormSubmit}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Modal
                    show={folderDeleteShow}
                    onHide={handleFolderDeleteClose}
                    animation={false}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Remove Folder</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete '{name}' and all Sub Folders? Doing so will delete all files associated to these folders.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleFolderDeleteClose}>
                            No
                        </Button>
                        <Button variant="primary" onClick={handleDelete}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

            <div className="folder">
                <a className="remove" onClick={handleFolderDeleteShow}><i className="fa fa-times-circle" aria-hidden="true"></i></a>
                <a className="contents" onClick={() => folderContents(id, name)}>
                    <i className="fa fa-folder fa-3x" aria-hidden="true"></i>
                </a>
                <div>
                    <a className="edit" onClick={handleFolderEditShow}><i className="fa fa-pencil-square-o" aria-hidden="true"></i></a>
                    <p>{name}</p>
                </div>
            </div>
        </>
    );
}

export default FolderContent;